import React from "react";
import ParagraphSection from "../../components/ParagraphSection";
import DemoSites from "../../components/DemoSites";
import SectionBreak from "../../components/SectionBreak";
import { sections, virtualRealityDemoSites } from "./content-data.json";

const { virtualRealitySection, augmentedRealitySection } = sections;

export default () => (
  <>
    <ParagraphSection
      id={virtualRealitySection.id}
      title={virtualRealitySection.title}
      subtitle={virtualRealitySection.subtitle}
      paragraphs={virtualRealitySection.paragraphs}
      icon={virtualRealitySection.icon}
      backgroundImage={virtualRealitySection.backgroundImage}
      images={virtualRealitySection.images}
    />
    <DemoSites sites={virtualRealityDemoSites} />
    <SectionBreak />
    <ParagraphSection
      id={augmentedRealitySection.id}
      title={augmentedRealitySection.title}
      subtitle={augmentedRealitySection.subtitle}
      paragraphs={augmentedRealitySection.paragraphs}
      icon={augmentedRealitySection.icon}
      backgroundImage={augmentedRealitySection.backgroundImage}
      images={augmentedRealitySection.images}
    />
  </>
);
