import styled from "styled-components";
import { SPACING, MOBILE_BREAKPOINT } from "../data/constants.json";

export default styled.div`
  width: 100%;
  height: 0;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    height: ${SPACING * 4}px;
  }
`;
