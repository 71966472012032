import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import SmoothCollapse from "react-smooth-collapse";
import { mdiChevronDown } from "@mdi/js";
import Button from "./Button";
import { color } from "../data/theme.json";
import { SPACING, ICON_SIZE, SHADOW, TINY_DEVICE_BREAKPOINT, MOBILE_BREAKPOINT } from "../data/constants.json";

const Card = styled.div`
  box-shadow: ${SHADOW};
  background-color: ${color.white};
  margin-bottom: ${SPACING * 2}px;
  position: relative;
  padding: ${SPACING * 3}px;
  @media (max-width: ${TINY_DEVICE_BREAKPOINT}px) {
    padding: ${SPACING * 2}px;
  }
`;

const CardHeader = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CardHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 4px;
`;

const Paragraph = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
`;

const ParagraphTitle = styled.div`
  font-weight: 600;
`;

const ParagraphBody = styled.div`
  font-weight: 400;
`;

const IconOuterContainer = styled.div`
  flex-grow: 1;
  min-width: ${ICON_SIZE}px;
  max-width: ${ICON_SIZE}px;
  margin-left: 16px;
`;

const IconInnerContainer = styled.div`
  width: 100%;
  height: ${ICON_SIZE}px;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CollapseButton = styled(Button)`
  margin-top: ${SPACING * 2}px;
`;

const CollapsibleParagraphs = ({ paragraphs }) =>
  paragraphs.map(paragraph => (
    <Paragraph key={paragraph.body}>
      {paragraph.title && <ParagraphTitle>{paragraph.title}</ParagraphTitle>}
      <ParagraphBody>{paragraph.body}</ParagraphBody>
    </Paragraph>
  ));

export default ({ title, subtitle, paragraphs, icon }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_BREAKPOINT}px)` });
  const [fullTextVisible, setFullTextVisible] = useState(false);

  useEffect(() => {
    if (!isMobile && !fullTextVisible) {
      setFullTextVisible(true);
    }
  }, [isMobile, fullTextVisible]);

  return (
    <Card>
      <CardHeader>
        <CardHeaderTextContainer>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </CardHeaderTextContainer>
        {icon && (
          <IconOuterContainer>
            <IconInnerContainer>
              <Icon src={icon} />
            </IconInnerContainer>
          </IconOuterContainer>
        )}
      </CardHeader>
      {paragraphs.length > 0 && (
        <>
          <Paragraph key={paragraphs[0].body}>
            {paragraphs[0].title && <ParagraphTitle>{paragraphs[0].title}</ParagraphTitle>}
            <ParagraphBody>{paragraphs[0].body}</ParagraphBody>
          </Paragraph>
          <SmoothCollapse expanded={fullTextVisible}>
            {/* paragraphs.slice(1) removes the first paragraph from the array of paragraphs. */}
            <CollapsibleParagraphs paragraphs={paragraphs.slice(1)} />
          </SmoothCollapse>
          {!fullTextVisible && (
            <CollapseButton
              label="Read More"
              icon={mdiChevronDown}
              iconPosition="right"
              onClick={() => setFullTextVisible(true)}
            />
          )}
        </>
      )}
    </Card>
  );
};
