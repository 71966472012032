import React, { useState } from "react";
import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import HeaderContent from "./HeaderContent";
import { MAX_WIDTH, MOBILE_BREAKPOINT, SPACING, SHADOW } from "../data/constants.json";
import { color } from "../data/theme.json";

const HeaderBar = styled(({ scrolled, ...props }) => <div {...props} />)`
  width: 100%;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  background-color: ${({ scrolled }) => (scrolled ? color.white : "transparent")};
  transition: ${({ scrolled }) => (scrolled ? "background-color 0s ease-in-out" : "background-color 0.3s ease-in-out")};

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: ${SHADOW};
    opacity: ${({ scrolled }) => (scrolled ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
  }
`;

const HeaderContentContainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  box-sizing: border-box;

  padding: ${SPACING * 1.5}px ${SPACING}px 0 ${SPACING}px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${SPACING}px 0px ${SPACING}px;
  }
`;

export default ({ general, sections, contactDetails }) => {
  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0 && !scrolled) {
      setScrolled(true);
    } else if (currPos.y === 0 && scrolled) {
      setScrolled(false);
    }
  });

  return (
    <HeaderBar id="header" scrolled={scrolled}>
      <HeaderContentContainer>
        <HeaderContent general={general} sections={sections} contactDetails={contactDetails} />
      </HeaderContentContainer>
    </HeaderBar>
  );
};
