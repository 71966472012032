import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import axios from "axios";
import ChatBot from "react-simple-chatbot";
import Loader from "react-loader-spinner";
import { color } from "../data/theme.json";

const SendEmail = ({ steps, triggerNextStep, productTitle }) => {
  useEffect(() => {
    const sendEmail = async () => {
      try {
        const { nameInput, emailInput, messageInput } = steps;
        const devMode = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
        if (!devMode) {
          await axios.post(`/.netlify/functions/send-email`, {
            contactName: nameInput.value,
            contactEmail: emailInput.value,
            message: messageInput.value,
            productTitle
          });
          triggerNextStep({ trigger: "sendMessageSuccess" });
        } else {
          // Simulate sending email by using a timeout in development mode.
          setTimeout(() => {
            triggerNextStep({ trigger: "sendMessageSuccess" });
          }, 3000);
        }
      } catch (error) {
        triggerNextStep({ trigger: "sendMessageError" });
      }
    };
    sendEmail();
  }, [productTitle, steps, triggerNextStep]);

  return (
    <div>
      <Loader
        type="ThreeDots"
        color={color.datacomBlue.base}
        secondaryColor={color.datacomBlue.base}
        radius={10}
        height={20}
        width={80}
      />
    </div>
  );
};

const chatBotTheme = {
  background: color.extraLightGrey,
  headerBgColor: color.datacomBlue.base,
  headerFontColor: color.white,
  headerFontSize: "15px",
  botBubbleColor: color.datacomBlue.base,
  botFontColor: color.white,
  userBubbleColor: color.white,
  userFontColor: color.darkestGrey
};

const ChatBotContainer = styled.div`
  font-weight: 600;
`;

export default ({ hide, productTitle }) => {
  return (
    <ThemeProvider theme={chatBotTheme}>
      <ChatBotContainer>
        <ChatBot
          floating
          floatingStyle={{
            marginBottom: hide ? -150 : 0,
            transition: "margin-bottom 0.5s ease"
          }}
          enableSmoothScroll
          enableMobileAutoFocus
          headerTitle=""
          steps={[
            {
              id: "entry",
              message: "Hi, would you like to know more?",
              trigger: "inputToKnowMore"
            },
            {
              id: "inputToKnowMore",
              options: [
                { value: "yes", label: "Yes, please!", trigger: "contactUs" },
                { value: "no", label: "No, thanks!", trigger: "cancelChat" }
              ]
            },
            {
              id: "cancelChat",
              message: "Okay, come back if you change your mind!",
              end: true
            },
            {
              id: "contactUs",
              message: "Great! You can contact us with any questions you have.",
              trigger: "askForName"
            },
            {
              id: "askForName",
              message: "What's your name?",
              trigger: "nameInput"
            },
            {
              id: "nameInput",
              user: true,
              validator: input => (input ? true : "Please enter your name!"),
              trigger: "thanksForName"
            },
            {
              id: "thanksForName",
              message: ({ previousValue }) => `Thanks, ${previousValue}!`,
              trigger: "askForEmail"
            },
            {
              id: "askForEmail",
              message: "What's your email address?",
              trigger: "emailInput"
            },
            {
              id: "emailInput",
              user: true,
              validator: input => {
                if (!input) {
                  return "Please enter your email address!";
                }
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input)) {
                  return "Email address invalid!";
                }
                return true;
              },
              trigger: "askForMessage"
            },
            {
              id: "askForMessage",
              message: "What would you like to ask us or find out more about?",
              trigger: "messageInput"
            },
            {
              id: "messageInput",
              user: true,
              validator: input => (input ? true : "Please enter a message!"),
              trigger: "thanksForMessage"
            },
            {
              id: "thanksForMessage",
              message: ({ steps }) => `Thanks for your message, ${steps.nameInput.value}!`,
              trigger: "beforeSend"
            },
            {
              id: "beforeSend",
              message: "I'll send that off to the team now...",
              trigger: "sendMessage"
            },
            {
              id: "sendMessage",
              component: <SendEmail productTitle={productTitle} />,
              replace: true,
              delay: 0,
              waitAction: true
            },
            {
              id: "sendMessageSuccess",
              message: "Your message was sent successfully! We'll try to get back to you as soon as possible.",
              end: true
            },
            {
              id: "sendMessageError",
              message: "Sorry, something went wrong and I wasn't able to send your message, would you like me to try again?",
              trigger: "trySendEmailAgainInput"
            },
            {
              id: "trySendEmailAgainInput",
              options: [
                { value: "yes", label: "Yes, please!", trigger: "sendMessage" },
                { value: "no", label: "No, thanks!", trigger: "cancelChat" }
              ]
            }
          ]}
        />
      </ChatBotContainer>
    </ThemeProvider>
  );
};
