import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import ColorCard from "./ColorCard";
import ContactDetails from "./ContactDetails";
import DownloadBrochureButton from "./DownloadBrochureButton";
import { MAX_WIDTH, SPACING, MOBILE_BREAKPOINT, RIGHT_HEADER_CONTENT_BREAKPOINT } from "../data/constants.json";

const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
  max-width: ${MAX_WIDTH}px;
  box-sizing: border-box;
  padding: 0;
  margin-top: ${SPACING}px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${SPACING}px ${SPACING}px ${SPACING}px;
    margin-top: ${SPACING * 2}px;
  }
`;

const ContactDetailsContainer = styled.div`
  margin-top: auto;
`;

export default ({ contentComponent, contactDetails, brochureUrl }) => {
  const Content = contentComponent;
  const mobileView = useMediaQuery({ query: `(max-width: ${RIGHT_HEADER_CONTENT_BREAKPOINT}px)` });
  return (
    <ContentContainer>
      <Content />
      {mobileView && (
        <ContactDetailsContainer>
          <ColorCard id="contact-details-footer">
            <ContactDetails contactDetails={contactDetails} />
            <DownloadBrochureButton brochureUrl={brochureUrl} />
          </ColorCard>
        </ContactDetailsContainer>
      )}
    </ContentContainer>
  );
};
