import React, { useState } from "react";
import styled from "styled-components";
import Icon from "@mdi/react";
import { mdiFullscreen } from "@mdi/js";
import ImageViewer from "./ImageViewer";
import { SPACING, MOBILE_BREAKPOINT, SHADOW } from "../data/constants.json";
import { color } from "../data/theme.json";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${SPACING}px;
  align-items: flex-end;
`;

const ScreenshotsTitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
`;

const ScreenshotsSubtitle = styled.div`
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  font-style: italic;
  margin-left: 4px;
`;

const ImagesContainer = styled(({ totalThumbnails, ...props }) => <div {...props} />)`
  display: grid;
  grid-template-columns: repeat(${({ totalThumbnails }) => totalThumbnails}, 1fr);
  grid-column-gap: ${SPACING}px;
  padding: 0 ${SPACING}px 0 ${SPACING}px;
  margin-bottom: ${SPACING * 2}px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-column-gap: ${SPACING * 2}px;
    padding: 0;
  }
`;

const ImageContainer = styled.div`
  height: 170px;
  flex: 1;
  position: relative;
  box-shadow: ${SHADOW};
  border-radius: 6px;
`;

const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  transition: opacity 0.15s ease-in-out 0s;
  opacity: 0;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const Image = styled(({ src, ...props }) => <div {...props} />)`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  border-radius: 6px;
  z-index: 50;
`;

const ImageThumbnail = ({ src, onClick }) => {
  return (
    <ImageContainer onClick={onClick}>
      <Image key={src} src={src} />
      <ImageOverlay>
        <Icon path={mdiFullscreen} size={3} color={color.white} />
      </ImageOverlay>
    </ImageContainer>
  );
};

export default ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  return (
    <>
      <TitleContainer>
        <ScreenshotsTitle>Screenshots</ScreenshotsTitle>
        <ScreenshotsSubtitle>(Click to enlarge)</ScreenshotsSubtitle>
      </TitleContainer>
      <ImagesContainer totalThumbnails={images.length}>
        <ImageViewer
          images={images}
          currentImageIndex={currentImageIndex}
          onClose={() => setCurrentImageIndex(null)}
          onChangeImageIndex={newIndex => setCurrentImageIndex(newIndex)}
        />
        {images.map((image, imageIndex) => (
          <ImageThumbnail key={image.thumbnail} src={image.thumbnail} onClick={() => setCurrentImageIndex(imageIndex)} />
        ))}
      </ImagesContainer>
    </>
  );
};
