import React from "react";
import ParagraphSection from "../../components/ParagraphSection";
import YouTubeVideo from "../../components/YouTubeVideo";
import { sections, youTubeVideoId } from "./content-data.json";
import ImageThumbnails from "../../components/ImageThumbnails";

const { introductionSection } = sections;

export default () => (
  <>
    <ParagraphSection
      id={introductionSection.id}
      title={introductionSection.title}
      subtitle={introductionSection.subtitle}
      paragraphs={introductionSection.paragraphs}
      icon={introductionSection.icon}
      backgroundImage={introductionSection.backgroundImage}
    />
    {introductionSection.images && <ImageThumbnails images={introductionSection.images} />}
    <YouTubeVideo videoId={youTubeVideoId} />
  </>
);
