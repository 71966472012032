import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import VirtualExperiencesContentLayout from "../modes/virtual-experiences/ContentLayout";
import virtualExperiencesContentData from "../modes/virtual-experiences/content-data.json";
import CEPContentLayout from "../modes/cep/ContentLayout";
import cepContentData from "../modes/cep/content-data.json";
import Site from "./Site";

const SITES = {
  CEP: {
    path: "/cep",
    contentComponent: CEPContentLayout,
    contentData: cepContentData
  },
  VIRTUAL_EXPERIENCES: {
    path: "/virtual-experiences",
    contentComponent: VirtualExperiencesContentLayout,
    contentData: virtualExperiencesContentData
  }
};

export default () => {
  return (
    <Router>
      <Switch>
        {Object.keys(SITES).map(siteKey => {
          const { path, contentComponent, contentData } = SITES[siteKey];
          return (
            <Route key={path} path={path}>
              <Site contentData={contentData} contentComponent={contentComponent} />
            </Route>
          );
        })}
        <Redirect from="*" to={SITES[Object.keys(SITES)[0]].path} />
      </Switch>
    </Router>
  );
};
