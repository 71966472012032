import React from "react";
import styled from "styled-components";
import { color } from "../data/theme.json";
import { SPACING, MOBILE_BREAKPOINT, NARROW_DEVICE_BREAKPOINT } from "../data/constants.json";

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftContainer = styled.div`
  margin-right: ${SPACING}px;
`;

const RightContainer = styled.div`
  margin-left: ${SPACING}px;
`;

const ContactTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
`;

const GeneralText = styled.div`
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  @media (max-width: ${NARROW_DEVICE_BREAKPOINT}px) {
    font-size: 12px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  color: ${color.lightBlue.base};
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    color: ${color.actionBlue};
  }
`;

export default ({ contactDetails }) => (
  <MainContainer>
    <LeftContainer>
      <ContactTitle>Contact</ContactTitle>
      <GeneralText>{contactDetails.name}</GeneralText>
      <GeneralText>
        <em>{contactDetails.jobTitle}</em>
      </GeneralText>
    </LeftContainer>
    <RightContainer>
      <GeneralText>
        <Link href={`mailto:${contactDetails.email}`}>{contactDetails.email}</Link>
      </GeneralText>
      <GeneralText>
        <Link href={`tel:${contactDetails.phoneNumber}`}>{contactDetails.phoneNumber}</Link>
      </GeneralText>
      <GeneralText>{contactDetails.address}</GeneralText>
    </RightContainer>
  </MainContainer>
);
