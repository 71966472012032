import React from "react";
import styled from "styled-components";
import { mdiArrowRight } from "@mdi/js";
import ColorCard from "./ColorCard";
import Button from "./Button";
import { SPACING, MOBILE_BREAKPOINT } from "../data/constants.json";

const MainContainer = styled.div`
  display: grid;
  grid-column-gap: ${SPACING * 2}px;
  grid-row-gap: ${SPACING * 2}px;
  margin: ${SPACING * 2}px 0 ${SPACING * 2}px 0;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin: ${SPACING * 4}px 0 ${SPACING * 2}px 0;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const EnterSiteButton = styled(Button)`
  margin-top: ${SPACING * 2}px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: auto;
`;

export default ({ sites }) => (
  <MainContainer>
    {sites.map(site => (
      <ColorCard key={site.title} title={site.title} subtitle={site.subtitle}>
        <Description>{site.description}</Description>
        <EnterSiteButton label="Enter Site" href={site.url} icon={mdiArrowRight} iconPosition="right" />
      </ColorCard>
    ))}
  </MainContainer>
);
