import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Skeleton from "react-loading-skeleton";
import YouTube from "react-youtube";
import { MOBILE_BREAKPOINT, MAX_WIDTH, SPACING } from "../data/constants.json";

const MainContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  margin-bottom: 0;
  margin-bottom: ${SPACING * 2}px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: ${SPACING}px;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const VideoSkeleton = styled(Skeleton)`
  border-radius: 5px;
`;

const calculateVideoSize = width => ({
  width,
  height: width / (16 / 9)
});

export default ({ videoId }) => {
  // Used to monitor when window is resizing.
  // This allows for the video to show a loading skeleton until resizing has stopped.
  const [resizing, setResizing] = useState(false);

  // Add a window resize listener on mount.
  useEffect(() => {
    window.addEventListener("resize", () => {
      setResizing(true);
      setTimeout(() => setResizing(false), 500);
    });
  }, []);

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_BREAKPOINT}px)` });

  // If mobile, the video width will be the width of the screen.
  let videoWidth = window.innerWidth;
  if (!isMobile) {
    videoWidth = (window.innerWidth >= MAX_WIDTH ? MAX_WIDTH : window.innerWidth) - SPACING * 2;
  }

  const videoSize = calculateVideoSize(videoWidth);

  const videoOptions = {
    width: videoSize.width,
    height: videoSize.height,
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      rel: 0,
      ecver: 2
    }
  };

  return (
    <MainContainer height={videoSize.height}>
      <VideoContainer width={videoSize.width} height={videoSize.height}>
        {!resizing && <YouTube videoId={videoId} opts={videoOptions} />}
      </VideoContainer>
      <VideoSkeleton width={videoSize.width} height={videoSize.height} />
    </MainContainer>
  );
};
