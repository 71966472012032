import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import prettyBytes from "pretty-bytes";
import { mdiFilePdfOutline } from "@mdi/js";
import Button from "./Button";
import { SPACING, RIGHT_HEADER_CONTENT_BREAKPOINT, NARROW_DEVICE_BREAKPOINT } from "../data/constants.json";

// ===== DEPRECATED =====
// Get the file size of the PDF via the http head of the file URL.
const getFileSize = url => {
  let fileSize = null;
  const http = new XMLHttpRequest();
  http.open("HEAD", url, false); // false = Synchronous
  http.send(null);
  if (http.status === 200) {
    fileSize = http.getResponseHeader("content-length");
  }
  return fileSize;
};

// Button height must be equal to height above logo minus the spacing constant, with spacing constant margin below.
// button height: 2 * spacing constant + nav link line height
// button margin bottom: spacing constant

const StyledButton = styled(Button)`
  padding: 0;
  margin: ${SPACING * 2}px 0 0 0;
  @media (min-width: ${RIGHT_HEADER_CONTENT_BREAKPOINT}px) {
    padding: 0 ${SPACING}px 0 ${SPACING}px;
    height: ${SPACING * 2 + 14}px;
    margin: 0 0 ${SPACING}px 0;
  }
`;

export default ({ brochureUrl }) => {
  const [fileSize, setFileSize] = useState(0);

  // ===== DEPRECATED =====
  useEffect(() => {
    setFileSize(getFileSize(brochureUrl));
  }, [brochureUrl]);

  const showFileSize = useMediaQuery({ query: `(min-width: ${NARROW_DEVICE_BREAKPOINT}px)` });

  return (
    <StyledButton
      label={`Download PDF brochure${fileSize && showFileSize ? ` (${prettyBytes(Number(fileSize))})` : ""}`}
      href={brochureUrl}
      icon={mdiFilePdfOutline}
      iconPosition="left"
      download
    />
  );
};
