import React from "react";
import styled from "styled-components";
import { SPACING, TINY_DEVICE_BREAKPOINT } from "../data/constants.json";
import { color } from "../data/theme.json";

const Card = styled.div`
  background-color: ${color.darkBlue.base};
  color: ${color.white};
  display: flex;
  flex-direction: column;
  padding: ${SPACING * 3}px;
  @media (max-width: ${TINY_DEVICE_BREAKPOINT}px) {
    padding: ${SPACING * 2}px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-top: 4px;
`;

const ContentContainer = styled(({ titleExists, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: ${({ titleExists }) => (titleExists ? 16 : 0)}px;
`;

export default ({ title, subtitle, children, id }) => (
  <Card id={id || undefined}>
    {title && <Title>{title}</Title>}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    <ContentContainer titleExists={title !== undefined}>{children}</ContentContainer>
  </Card>
);
