import React from "react";
import styled from "styled-components";
import Icon from "@mdi/react";
import { color } from "../data/theme.json";

const StyledIcon = styled(({ iconPosition, ...props }) => <Icon {...props} />)`
  margin-${({ iconPosition }) => (iconPosition === "right" ? "left" : "right")}: 5px;
`;

const Button = styled.a`
  height: 50px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-decoration: none;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-size: 14px;
  line-height: 14px;
  font-weight: 600;

  color: ${color.white};
  background-color: ${color.accentPink.base};
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${color.accentPink["80"]};
  }
`;

export default ({ label, icon, iconPosition, onClick, href, download, className }) => (
  <Button className={className} onClick={onClick} href={href} download={download}>
    {icon && iconPosition === "left" && (
      <StyledIcon iconPosition={iconPosition} path={icon} size={0.9} color={color.white} />
    )}
    <div>{label}</div>
    {icon && iconPosition === "right" && (
      <StyledIcon iconPosition={iconPosition} path={icon} size={0.9} color={color.white} />
    )}
  </Button>
);

Button.defaultProps = {
  icon: null,
  iconPosition: "left",
  onClick: () => {},
  download: false
};
