import React from "react";
import styled from "styled-components";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const LightboxContainer = styled.div`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export default ({ images, currentImageIndex, onClose, onChangeImageIndex }) => {
  return currentImageIndex !== null ? (
    <LightboxContainer>
      <Lightbox
        imageTitle={images[currentImageIndex].title}
        imageCaption={images[currentImageIndex].description}
        mainSrc={images[currentImageIndex].main}
        nextSrc={images[(currentImageIndex + 1) % images.length].main}
        prevSrc={images[(currentImageIndex + images.length - 1) % images.length].main}
        onCloseRequest={onClose}
        onMovePrevRequest={() => onChangeImageIndex((currentImageIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => onChangeImageIndex((currentImageIndex + images.length + 1) % images.length)}
      />
    </LightboxContainer>
  ) : null;
};
