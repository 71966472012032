import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import DownloadBrochureButton from "./DownloadBrochureButton";
import ContactDetails from "./ContactDetails";
import { color } from "../data/theme.json";
import {
  SPACING,
  MOBILE_BREAKPOINT,
  RIGHT_HEADER_CONTENT_BREAKPOINT,
  NARROW_DEVICE_BREAKPOINT,
  TINY_DEVICE_BREAKPOINT
} from "../data/constants.json";

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  margin-right: ${SPACING}px;
`;

const RightContainer = styled.div`
  margin-left: ${SPACING}px;
  margin-bottom: ${SPACING * 1.5}px;
`;

const LogoTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  margin-bottom: ${SPACING * 1.5}px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    height: auto;
    align-items: flex-start;
  }
  @media (max-width: ${TINY_DEVICE_BREAKPOINT}px) {
    height: auto;
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
`;

const Logo = styled.img`
  margin: 0 ${SPACING}px 0 ${SPACING}px;
  height: 20px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 ${SPACING}px 0 0;
    height: 30px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-left: ${SPACING * 2}px;
`;

const Title = styled(({ length, ...props }) => <div {...props} />)`
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  max-width: none;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 28px;
    line-height: 28px;
    max-width: ${({ length }) => 250 + (length > 20 ? 100 : 0)}px;
  }
  @media (max-width: ${NARROW_DEVICE_BREAKPOINT}px) {
    font-size: 19px;
    line-height: 19px;
    max-width: none;
  }
`;

const NavContainer = styled.div`
  margin: ${SPACING}px 0 ${SPACING * 2}px 0;
  display: flex;
  flex-direction: row;
`;

const NavItem = styled.div`
  margin-right: ${SPACING * 3}px;
  color: ${color.actionBlue};
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

const scrollToSection = elementId => {
  const headerHeight = document.getElementById("header").offsetHeight;
  const section = document.getElementById(elementId);
  const sectionPosition = section.offsetTop;
  window.scrollTo({
    top: sectionPosition - headerHeight - SPACING * 2,
    behavior: "smooth"
  });
};

export default ({ general, sections, contactDetails }) => {
  const showRightContent = useMediaQuery({ query: `(min-width: ${RIGHT_HEADER_CONTENT_BREAKPOINT}px)` });
  const showNav = useMediaQuery({ query: `(min-width: ${MOBILE_BREAKPOINT}px)` });
  const showShortTitle = useMediaQuery({ query: `(max-width: ${NARROW_DEVICE_BREAKPOINT}px)` });
  return (
    <ContentContainer>
      <LeftContainer>
        {showNav && (
          <NavContainer>
            {Object.keys(sections).map(sectionKey =>
              sections[sectionKey].showInNavigation ? (
                <NavItem key={sectionKey} onClick={() => scrollToSection(sections[sectionKey].id)}>
                  {sections[sectionKey].navigationLabel || sections[sectionKey].title}
                </NavItem>
              ) : null
            )}
          </NavContainer>
        )}
        <LogoTitleContainer>
          <a href="https://www.datacom.co.nz/Home.aspx">
            <LogoContainer>
              <Logo src="/images/common/datacom_logo.png" />
            </LogoContainer>
          </a>
          <TextContainer>
            {showShortTitle && general.shortTitle ? (
              <Title length={general.shortTitle.length}>{general.shortTitle}</Title>
            ) : (
              <Title length={general.title.length}>{general.title}</Title>
            )}
          </TextContainer>
        </LogoTitleContainer>
      </LeftContainer>
      {showRightContent && (
        <RightContainer>
          <DownloadBrochureButton brochureUrl={general.brochureUrl} />
          <ContactDetails contactDetails={contactDetails} />
        </RightContainer>
      )}
    </ContentContainer>
  );
};
