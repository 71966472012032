import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import ParagraphCard from "./ParagraphCard";
import ImageThumbnails from "./ImageThumbnails";
import { MAX_WIDTH, SPACING, MOBILE_BREAKPOINT, BACKGROUND_LAYER_IMAGE_OFFSET } from "../data/constants.json";

const MainContainer = styled(({ imagesExist, ...props }) => <div {...props} />)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  margin-bottom: ${({ imagesExist }) => (imagesExist ? SPACING * 2 : 0)}px;
  min-height: ${MAX_WIDTH / 2 + BACKGROUND_LAYER_IMAGE_OFFSET}px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-height: 0;
  }
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  margin-top: 0;
  padding-left: 0px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    max-width: ${MAX_WIDTH / 2}px;
    margin-top: ${BACKGROUND_LAYER_IMAGE_OFFSET}px;
    padding-left: 20px;
  }
`;

const BackgroundImage = styled(({ src, ...props }) => <div {...props} />)`
  background-image: url(${({ src }) => src});
  background-size: cover;
  position: absolute;
  width: ${MAX_WIDTH / 2 + BACKGROUND_LAYER_IMAGE_OFFSET}px;
  height: ${MAX_WIDTH / 2 + BACKGROUND_LAYER_IMAGE_OFFSET}px;
  left: 0;
  top: 0;
`;

export default ({ id, title, subtitle, paragraphs, icon, backgroundImage, images }) => {
  const showBackgroundImage = useMediaQuery({ query: `(min-width: ${MOBILE_BREAKPOINT}px)` });
  return (
    <MainContainer id={id} imagesExist={!!images}>
      {showBackgroundImage && <BackgroundImage src={backgroundImage} />}
      <ContentContainer>
        <ParagraphCard title={title} subtitle={subtitle} paragraphs={paragraphs} icon={icon} />
        {images && <ImageThumbnails images={images} />}
      </ContentContainer>
    </MainContainer>
  );
};
