import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import PageContent from "./PageContent";
import ChatBot from "./ChatBot";
import Header from "./Header";

import { color } from "../data/theme.json";

const SiteContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("/images/background.png");
  background-attachment: fixed;
  background-size: 100% auto;
  position: relative;
  box-sizing: border-box;
  color: ${color.darkBlue.base};
`;

export default ({ contentComponent, contentData }) => {
  // Set page title on first load or when site changes.
  useEffect(() => {
    document.title = `Datacom - ${contentData.general.title}`;
  }, [contentData]);

  const [chatBotHidden, setChatBotHidden] = useState(false);

  useScrollPosition(() => {
    const contactDetailsFooterElement = document.getElementById("contact-details-footer");
    if (!contactDetailsFooterElement) {
      return;
    }
    const contactDetailsFooterHeight = contactDetailsFooterElement.offsetHeight;
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight - contactDetailsFooterHeight) {
      if (!chatBotHidden) {
        setChatBotHidden(true);
      }
    } else if (chatBotHidden) {
      setChatBotHidden(false);
    }
  });

  return (
    <SiteContainer>
      <Header general={contentData.general} sections={contentData.sections} contactDetails={contentData.contactDetails} />
      <PageContent
        contentComponent={contentComponent}
        contactDetails={contentData.contactDetails}
        brochureUrl={contentData.general.brochureUrl}
      />
      <ChatBot hide={chatBotHidden} productTitle={contentData.general.productTitle} />
    </SiteContainer>
  );
};
